@font-face {
  font-family: 'basier_circleregular';
  src: url('fonts/basiercircle-regular-webfont.eot');
  src: url('fonts/basiercircle-regular-webfont.eot?#iefix') format('embedded-opentype'),
       url('fonts/basiercircle-regular-webfont.woff2') format('woff2'),
       url('fonts/basiercircle-regular-webfont.woff') format('woff'),
       url('fonts/basiercircle-regular-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'basier_circlesemibold';
  src: url('fonts/basiercircle-semibold-webfont.eot');
  src: url('fonts/basiercircle-semibold-webfont.eot?#iefix') format('embedded-opentype'),
       url('fonts/basiercircle-semibold-webfont.woff2') format('woff2'),
       url('fonts/basiercircle-semibold-webfont.woff') format('woff'),
       url('fonts/basiercircle-semibold-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'basier_circlemedium';
  src: url('fonts/basiercircle-medium-webfont.eot');
  src: url('fonts/basiercircle-medium-webfont.eot?#iefix') format('embedded-opentype'),
       url('fonts/basiercircle-medium-webfont.woff2') format('woff2'),
       url('fonts/basiercircle-medium-webfont.woff') format('woff'),
       url('fonts/basiercircle-medium-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@import './variables.scss';
@import './animations.scss';
@import './table.scss';
@import './nav.scss';
@import './route.scss';
@import './schedule.scss';
@import './year.scss';
@import './tooltip.scss';
@import './tasks.scss';
@import './clients.scss';
@import './projects.scss';
@import './invoices.scss';
@import './modal.scss';
@import './messages.scss';
@import './overlay.scss';
@import './options.scss';

html,
body {
  height: 100%;
}

body,
input,
textarea,
select,
button {
  font-weight: 300;
  font-size: 14px;
  font-family: "basier_circleregular", "Helvetica Neue", "Helvetica", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  outline: none;
}

button {
  margin: 0;

  &[disabled] {
    pointer-events: none;
  }

}

input[type=text],
input[type=search],
textarea {
  -webkit-appearance: none;
}

::-webkit-input-placeholder {
  @include themed() {
    color: t($color__text);
  }
}
:-ms-input-placeholder {
  @include themed() {
    color: t($color__text);
  }
}
::placeholder {
  @include themed() {
    color: t($color__text);
  }
}

.cursor {

  &-default {
    cursor: default;
  }

}

input,
button {
  background: none;
  border: none;
  border-radius: $size / 2;
}

button {
  cursor: pointer;
}

body {
  margin: 0;
  padding: 0;

  h1 {
    font-family: 'basier_circlemedium';
    font-weight: 300;
  }

  h2 {
    font-size: $size * 2;
    font-family: 'basier_circlemedium';
    font-weight: 300;
  }

  h3 {
    font-size: $size * 2;
    font-family: 'basier_circlemedium';
    font-weight: 300;
  }

}

*,
*::before,
*::after {
  box-sizing: border-box;
  transition: color 0.1s ease-in-out, border-color 0.1s ease-in-out;
}

.hidden {
  display: none;
}

hr {
  display: none;
  height: 1px;

  @include themed() {
    background: rgba(t($color__text), 0.05);
  }

  border: none;
}

main a:not(.btn):not(.icon):not(.option):not(.item) {
  position: relative;

  @include themed() {
    color: t($color__primary);
  }

  box-shadow: 0 1px 0 0 transparent;
  text-decoration: none;

  &:hover {

    @include themed() {
      box-shadow: 0 1px 0 0 t($color__primary);
    }

  }

}

b {
  font-family: 'basier_circlesemibold';
}

body,
html {
  height: 100%;

  @media (min-width: 1024px) {
    overflow: hidden;
  }

}

.btn {
  display: flex;
  align-items: center;
  width: auto;
  justify-content: center;
  font-size: 14px;
  border: none;
  border-radius: $size / 2;
  cursor: pointer;
  transition: background-color 0.1s ease-in-out;
  padding: $size ($size * 2);
  margin: 0 auto;
  height: $size * 5;
  text-decoration: none;

  @include themed() {
    background-color: t($color__accent);
    color: t($color__secondary);
  }

  &:hover,
  &:focus {
    outline: none;
    box-shadow: none;

    @include themed() {
      background-color: t($color__primary);
      color: t($color__secondary);
    }

  }

  > span:not(.tooltip) {
    margin-right: $size * 2;

    &:last-child {
      margin-right: 0;
    }

  }

  &__mini {
    padding: $size;
    height: $size * 4;
    font-size: 12px;
  }

  &__secondary {
    margin: 0 auto 0 0;

    @include themed() {
      background-color: rgba(t($color__primary), 0.2);
    }

  }

  &__delete {
    margin: 0 auto 0 0;

    @include themed() {
      background-color: t($color__negative);
    }

  }

  &__inline {
    width: auto;
    display: inline-flex;
    padding-left: $size * 4;
    padding-right: $size * 4;
  }

  &__auto {
    width: auto;
    display: inline-flex;
    white-space: nowrap;
  }

  &__outline {

    @include themed() {
      background-color: transparent;
      border: 1px solid t($color__dropdown__menu);
      color: t($color__accent);

      &:hover,
      &:focus {
        background-color: t($color__modal__tip);
        color: t(color__text);
      }

      &[disabled] {
        background: t($color__root)!important;
      }

    }

  }

  &__transparent {

    @include themed() {
      background-color: transparent;
      color: t($color__text);

      &:hover,
      &:focus {
        background-color: t($color__modal__tip);
        color: t(color__text);
      }

    }

  }

  &__tight {
    margin: 0;
  }

  &__square {
    padding-left: $size;
    padding-right: $size;

    svg {
      margin: 0;
    }

  }

  &__muted {

    @include themed() {
      background-color: t($color__modal__tip);
      color: t(color__text);

      &:hover,
      &:focus {
        background-color: t($color__modal__tip);
        color: t(color__primary);
      }

    }

  }

  &__cancel {
    margin: 0;

    @include themed() {
      color: t($color__negative);
      background: none;
      border: 1px solid t($color__negative);

      &:hover,
      &:focus {
        color: #fff;
        background: t($color__negative);
      }

    }

  }

  &__link {
    @include themed() {
      background: transparent;
      padding: $size 0;
      height: auto;
      color: t($color__accent);

      &:hover,
      &:focus {
        color: currentColor;
        background: transparent;
      }

    }

    span {
      line-height: $size * 2;
    }

  }

  &__left {
    justify-content: flex-start;
  }

  &[disabled] {
    pointer-events:initial;
    cursor: initial;

    @include themed() {
      background: t($color__modal__bg);
    }

    .tooltip + span {
      margin-left: $size;
    }

  }

  .loader__button {
    display: none;
  }

  &__loading {

    .loader__button {
      position: absolute!important;
      display: block;
    }

    span {
      opacity: 0;
    }

  }

}

div#mobile {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  backdrop-filter: blur(8px);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 18px;

  @include themed() {
    background: t($color__modal__bg);
  }

  @media (min-width: 1024px) {
    display: none;
  }

}

div#root {
  width: 100%;
  height: 100%;
  pointer-events: none;
  overflow: hidden;

  .theme {
    width: 100%;
    height: 100%;

    @media (min-width: 1024px) {
      display: flex;
      pointer-events: initial;
      overflow: auto;
    }

    &--default {

      .video {
        img {
          filter: brightness(0);
        }
      }

    }

  }

}

main,
.overlay {

  .loader,
  .loader:after {
    border-radius: 50%;
    width: $size * 8;
    height: $size * 8;

  }
  .loader {
    margin: 0 auto;
    position: relative;
    text-indent: -9999em;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: spin 1.1s infinite linear;
    animation: spin 1.1s infinite linear;
    border-width: $size;
    border-style: solid;

    @include themed() {
      border-top-color: rgba(t($color__accent), 0.2);
      border-right-color: rgba(t($color__accent), 0.2);
      border-bottom-color: rgba(t($color__accent), 0.2);
      border-left-color: rgba(t($color__accent), 0.8);
    }

    &__small {
      width: $size * 4;
      height: $size * 4;
      border-width: $size / 2;
    }

    &__button {
      width: $size * 2;
      height: $size * 2;
      border-width: $size / 4;

      @include themed() {
        border-top-color: rgba(t($color__secondary), 0.2);
        border-right-color: rgba(t($color__secondary), 0.2);
        border-bottom-color: rgba(t($color__secondary), 0.2);
        border-left-color: rgba(t($color__secondary), 0.8);
      }

    }

    &.top-50 {
      top: 50%;
    }

  }

  .field .react-select-container {

    .react-select__control {
      width: 100%;
      min-height: 40px;
    }
  
    .react-select__input input:focus {
  
      @include themed() {
        border-color: none;
        box-shadow: none;
      }
  
    }
  
    input {
      min-height: auto;
      height: auto;
      width: 100%;
    }
  
  }
  
  .react-select {

    &__dropdown-indicator {
      padding-right: $size * 1.5;
      opacity: 0.5;
      color: inherit!important;
    }

    &-container {
      width: 100%;
      max-width: 50%;
    }
  
    &__control {
      background: none;
      border-width: 1px;
      border-radius: $size / 2;
      min-height: 1px;
  
      @include themed() {
        border-color: t($color__input);
      }
  
      &:hover {
  
        @include themed() {
          border-color: t($color__input);
        }
  
      }
  
      &--is-focused,
      &--is-focused:hover {
  
        @include themed() {
          box-shadow: none;
          border-color: t($color__accent);
        }
  
      }

      // &--menu-is-open {

      //   + .react-select__menu {
      //     z-index: 2;
      //   }

      // }
  
    }
  
    &__indicator-separator {
      display: none;
    }
  
    &__single-value,
    &__input {
  
      @include themed() {
        color: t($color__primary);
      }
  
    }
  
    &__menu {
      z-index: 2;
  
      @include themed() {
        background: t($color__dropdown);
        color: t($color__dropdown__text);
        box-shadow: 0 0 0 1px t($color__dropdown__border);
      }
  
    }
  
    &__option {
      transition: none;
      cursor: pointer;
  
      &--is-disabled {
  
        @include themed() {
          color: t($color__text);
          opacity: 0.5;
          pointer-events: none;
        }
  
      }
  
      &--is-focused {
        @include themed() {
          background: t($color__dropdown__menu__switch);
        }
      }

      &--is-selected {
  
        @include themed() {
          color: t($color__dropdown__text);
          background: t($color__dropdown__menu__switch__on);
        }
  
      }
  
    }
  
  }

}

.overlay {
  
  .react-select {
  
    &__control {
      background: none;
  
      @include themed() {
        //border-color: t($color__modal__border);
        background: t($color__dropdown__toggle);
        color: t($color__dropdown__toggle__text);
      }
  
      &:hover {
  
        @include themed() {
          //border-color: t($color__modal__border);
          background: t($color__dropdown__toggle);
          color: t($color__dropdown__toggle__text);
        }
  
      }
  
    }

  }

}

main {

  @media (min-width: 1024px) {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  @include themed() {
    color: t($color__primary);
    background: t($color__nav);
  }

  .loading {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;

    &__text {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      span {
        margin-top: $size * 3;
        opacity: 0.5;
      }

    }

  }

  > section {
    width: 100%;
    display: flex;
    flex-direction: column;

    @media (min-width: 1024px) {
      @include scroll();
    }

    header {
      display: flex;
      align-items: baseline;
      justify-content: space-between;
      align-items: center;
      padding: ($size * 3);

      @media (min-width: 640px) {
        padding: ($size * 4);
      }

      h2 {
        flex: 1;
        font-size: $size * 3;
        line-height: $size * 3;
        margin: 0;

        @include themed() {
          color: t($color__primary);
        }

        @media (min-width: 640px) {
          // font-size: 32px;
          // line-height: $size * 5;
        }

      }

      span {
        position: relative;

        &.last {
          margin-right: -$size;
        }

        a {
          display: block;
          // margin: 0 0 0 ($size * 2);
          padding: 0 $size;

          &:hover {

            @include themed() {
              box-shadow: none;
            }

            svg {
              display: block;

              * {

                @include themed() {
                  stroke: t($color__accent);
                }

              }

            }

          }

          svg {
            display: block;

            * {

              @include themed() {
                stroke: t($color__primary);
              }

            }

          }

        }

        // &.component__add {
        //
        //   button {
        //     font-size: 14px;
        //     width: auto;
        //     padding-left: $size * 2;
        //     padding-right: $size * 2;
        //
        //     @include themed() {
        //       color: t($color__button);
        //       background: t($color__primary);
        //     }
        //
        //     &:hover {
        //
        //       @include themed() {
        //         background: t($color__accent);
        //       }
        //
        //     }
        //
        //   }
        //
        // }

        &.component__edit {

          button {

            // @include themed() {
            //   background: t($color__primary);
            // }
            //
            // position: relative;
            // width: auto;
            // padding: 0 ($size * 2);

            &::after {
              display: block;
              content: 'Edit';
              width: 100%;
              font-size: 16px;

              @include themed() {
                color: t($color__secondary);
              }

            }

            // &:hover {
            //
            //   @include themed() {
            //     background-color: t($color__accent);
            //   }
            //
            // }

          }

        }

        &.schedule__reset {

          button {
            font-size: 14px;
            width: auto;
            padding-left: $size * 2;
            padding-right: $size * 2;

            @include themed() {
              color: t($color__button);
              background: t($color__primary);
            }

            &:hover {
              @include themed() {
                background: t($color__accent);
              }
            }

          }

        }

        &.schedule__search {

          button {
            @include themed() {
              background: t($color__primary) url('../images/search__' + t($color__icon) + '.svg') center no-repeat;
            }

            &:hover {
              @include themed() {
                background-color: t($color__accent);
              }
            }

          }

        }

        &.month__prev {

          button {
            @include themed() {
              background: t($color__primary) url('../images/prev__' + t($color__icon) + '.svg') 45% center no-repeat;
            }

            &:hover {
              @include themed() {
                background-color: t($color__accent);
              }
            }

          }

        }

        &.month__next {

          button {
            @include themed() {
              background: t($color__primary) url('../images/next__' + t($color__icon) + '.svg') center no-repeat;
            }

            &:hover {
              @include themed() {
                background-color: t($color__accent);
              }
            }

          }

        }

      }

      form {
        position: relative;
        display: flex;
        align-items: flex-end;
        border-radius: $size * 4;

        input {
          background: none;
          position: relative;
          width: 0;
          min-width: $size * 6;
          height: $size * 5;
          text-indent: -9999px;
          transition: width 0.1s ease-in-out;
          z-index: 1;
          cursor: pointer;

          &:focus {

            @include themed() {
              background: t($color__grey);
            }

            width: $size * 32;
            text-indent: 0;
            border-color: transparent;
            z-index: 0;
            cursor: text;
            padding-left: $size * 2;
          }

        }

        button {
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
        }

      }

    }

    section {
      position: relative;
      flex-direction: column;
      @include scroll();
      flex: 1;
      padding: 0 ($size * 3);
      overscroll-behavior-y: none;

      @media (min-width: 640px) {
        padding: 0 ($size * 4);
      }

    }

  }

}

.multiple {
  display: flex;
  justify-content: space-between;
  margin-left: -($size);
  margin-right: -($size);

  .field {
    margin: 0 $size;

    &__helper {
      flex: 1;
    }

    &__switch {

      input + span {
        margin-top: $size;

        &::before,
        &::after {
          border-radius: $size / 2;
        }

      }

    }

  }

}

.group {
  padding: ($size * 5);
  border-radius: $size;
  margin: ($size * 4) 0;

  @include themed() {
    background: t($color__secondary);
    box-shadow: 0 0 0 1px t($color__border);
  }

  &:first-child {
    margin-top: 0;
  }

  > :not(template) ~ :not(template) {
    margin-top: $size * 3;
    margin-bottom: 0;

    &.trial {
      margin-bottom: -40px;
      margin-left: -40px;
      margin-right: -40px;
      margin-top: 40px;
    }

  }

  .field__lineitem {
    transition: all 0.2s ease-in-out;
  }

  .field__group__single {
    display: none;
  }

  &__collapsed {

    .field__lineitem {
      pointer-events: none;
      opacity: 0.25;
    }

    .field__group__single {
      display: flex;
    }

    .add_custom {
      display: none;
    }

  }

}

hr {
  display: block;
  height: 1px;
  width: 100%;
  
  @include themed() {
    background: t($color__border);
  }

}

.inline {
  display: flex;
  align-items: center;

  > :not(template) ~ :not(template) {
    margin-left: ($size * 4);
  }

}

.field {

  a {
    color: inherit;
    border-bottom-width: 1px;
  }

  label {
    font-family: 'basier_circleregular';
    position: relative;
    display: block;
    width: 100%;

    @include themed() {
      color: t($color__text);
    }

    > span {

      + .helper,
      + div,
      + textarea,
      + input {
        margin: $size 0 0 0;
      }
  
    }

    > span,
    > div {
      display: flex;
      align-items: center;
      max-width: 100%;

      &.simple {
        display: block;
      }

      &.justify {
        justify-content: space-between;
      }

      .error {
        margin-left: auto;

        @include themed() {
          color: t($color__negative);
        }

      }

      .tooltip {
        margin-left: $size;
      }

    }

    textarea,
    input {
      width: 100%;
      font-weight: 300;
      padding: 0 ($size * 2);
      border-radius: 0;

      &.full {
        width: auto;
      }

      @include themed() {
        border: 1px solid t($color__input);
        background: transparent;
        color: t($color__primary);
      }

      border-radius: $size / 2;
      cursor: text;
      transition: border-color 0.1s ease-in-out;
      height: $size * 5;

      &:focus {
        background-color: transparent;
        outline: none;

        @include themed() {
          border-color: t($color__accent);
        }

      }

      &:disabled {

        @include themed() {
          background: rgba(t($color__grey), 0.5);
        }

        cursor: not-allowed;
      }

    }

    textarea {
      display: block;
      padding: $size * 2;
      min-height: $size * 12;
      resize: vertical;

      &.noResize {
        resize: none;
      }

      &.tall {
        min-height: $size * 24;
      }

    }

    + div {
      margin: ($size * 2) 0 0 0;

      &.hex_colour {
        margin: 0 0 0 ($size * 2);
      }

      &.react-select-container {
        position: relative;
        z-index: 10;
        margin-top: $size;
      }

    }

    + small {
      display: block;
      margin: $size 0 0 0;
    }

  }

  small {
    display: block;
    margin: $size 0 0 0;

    @include themed() {
      color: t($color__text);

      &.link {
        cursor: pointer;
        color: t($color__accent);

        &:hover,
        &:focus {
          color: t($color__primary);
        }

      }

    }

  }

  &__placeholder {

    label {

      span {
        display: none;
      }

    }

  }

  &__hidden {
    display: none;
  }

  &__hours {
    width: $size * 12;
  }

  &__note {
    margin-top: $size * 3;

    label {
      display: flex;
      align-items: center;
      padding: $size 0;

      @include themed() {
        border-top: 1px solid rgba(t($color__text), 0.05);
      }

      span {
        display: none;
      }

      svg {
        margin-right: $size;

        @include themed() {
          rect,
          path {
            fill: t($color__text);
          }
        }

      }

      input,
      input:focus {
        margin: 0;
        padding-left: 0;
        padding-right: 0;

        @include themed() {
          border: none;
          box-shadow: none;
        }

      }

    }

  }

  &__group {
    display: flex;
    margin: $size 0;

    > :not(template) ~ :not(template) {
      margin-left: $size;
    }

    .field {
      margin-top: 0;
      margin-bottom: 0;
    }

    &__summary {
      justify-content: flex-end;

      > :not(template) ~ :not(template) {
        margin-left: $size * 4;
      }

    }

  }

  &__buttons {
    padding: ($size * 3) ($size * 4);
    // margin: -($size * 3);
    margin: ($size * 4) 0 0 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @include themed() {
      background-color: t($color__schedule__booked);
    }

    a,
    span {
      margin: 0 auto 0 0;
    }

    button.btn {
      margin: 0;
      order: 2;
    }

    &__simple {
      padding: 0;
      margin: 0;
      justify-content: space-between;

      @include themed() {
        background: none;
      }

    }

  }

  &__inline {
    display: flex;
    align-items: flex-end;

    svg {
      margin-right: $size * 2;
    }

  }

  &__switch {
    position: relative;

    label {
      position: relative;
      display: inline;

      input {
        display: none;
        margin: ($size * 2) 0 0 0;
        width: 0;

        * {
          cursor: pointer!important;
        }

        &:focus {
          border: none!important;
          box-shadow: none!important;
        }

        + span {
          position: relative;
          display: inline-block;
          margin: ($size * 2) 0 0 0;

          @include themed() {
            box-shadow: 0 0 0 4px inherit;
          }

          &::before {
            position: absolute;
            content: "N";
            display: block;
            width: $size * 5;
            height: $size * 5;
            border-radius: $size * 3;
            text-align: center;
            line-height: $size * 5;
            transition: all 0.2s ease-in-out;

            @include themed() {
              background: t($color__input);
              color: t($color__secondary);
            }

          }

          &::after {
            content: "";
            display: block;
            width: $size * 12;
            height: $size * 5;
            border-radius: $size * 3;
            position: relative;
            z-index: 2;

            @include themed() {
              border: 1px solid t($color__input);
            }

          }

        }

        &:checked {

          + span {

            &::before {
              content: "Y";
              left: auto;
              transform: translateX($size * 7);
              z-index: 3;

              @include themed() {
                background: t($color__accent);
              }

            }

          }

        }

      }

    }

    &__on_off {

      label {

        input {

          + span {
            display: block;
            margin: 0;

            &::before {
              content: "Off";
              width: $size * 6;
              height: $size * 4;
              line-height: $size * 4;
            }
 
            &::after {
              width: $size * 10;
              height: $size * 4;
            }

          }
  
          &:checked {
  
            + span {
  
              &::before {
                content: "On";
                transform: translateX($size * 4);
              }
  
            }
  
          }
  
        }
  
      }

    }

  }

  &__checkbox {
    position: relative;

    label {

      &.standard {
        display: flex;
        align-items: center;
        
        input {
          height: initial;
          width: initial;
        }

        > :not(template) ~ :not(template) {
          margin-left: $size;
        }

      }

      &:not(.standard) {
        position: relative;
        cursor: pointer;
  
        span {
          display: block;
          font-size: 0;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          z-index: 2;
          line-height: $size * 5;
          text-align: center;
          border-radius: $size * 3;
  
          @include themed() {
            background: t($color__secondary);
          }
  
          &::first-letter,
          &.currency,
          &.wide {
            font-size: 14px;
          }
  
          &.wide {
            padding: 0 $size;
          }
  
        }
  
        input {
          margin: 0;
          position: relative;
          display: block;
          width: $size * 5;
          height: $size * 5;
          border-radius: 0;
  
          @include themed() {
            background: t($color__accent);
            opacity: 0;
          }
  
          &:focus {
            border: none!important;
            box-shadow: none!important;
          }
  
          + span {
  
            @include themed() {
              color: t($color__primary);
              box-shadow: inset 0 0 0 1px t($color__input);
            }
  
          }
  
          &:checked {
  
            &::after {
  
              @include themed() {
                background: t($color__accent);
                border-color: t($color__accent);
              }
  
            }
  
            + span {
  
              @include themed() {
                color: t($color__secondary);
                background: t($color__accent);
                box-shadow: none;
              }
  
            }
  
          }
  
        }
  
      }

    }

    &__wide {

      label {

        input {
          width: $size * 12;
        }

        span {
          font-size: 14px;
        }

      }

    }

  }

  &__grow {
    flex-basis: 100%;
  }

  &__price {
    width: 100%;
    max-width: 140px;
  }

  &__tax {
    width: 100%;
    max-width: 100px;
  }

  &__summary {
    width: 100%;
    max-width: fit-content;
    margin-left: $size * 4;

    span {
      display: block;
      opacity: 0.5;
      margin-bottom: $size;
    }

    h3 {
      font-family: 'basier_circleregular';
      font-size: 24px;
      font-weight: 300;
      margin: ($size) 0;
      
      @include themed() {
        color: t($color__primary);
      }

    }

  }

  &__button {
    width: 100%;
    max-width: $size * 5;
  }

  &__helper {

    label {

      .helper {
        position: relative;

        span {
          position: absolute;
          top: 1px;
          left: 1px;
          bottom: 1px;
          width: $size * 5;
          text-align: center;
          border-radius: 4px 0 0 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0;

          @include themed() {
            background: t($color__grey);
            color: t($color__text);
          }
        }

        input {
          padding-left: $size * 7;
        }

      }

    }

  }

  &__helper-small {

    label {

      .helper {

        input {
          height: $size * 4;
        }

      }

    }

  }

  &__helper-percentage {

    label {

      .helper {

        span {
          left: auto;
          right: 1px;
          border-radius: 0 4px 4px 0;
        }

        input {
          padding-left: $size * 2;
          padding-right: $size * 7;
        }

      }

    }

  }

  &__fixed {
    display: flex;

    .button__group {
      display: flex;
      align-items: center;
      margin-left: auto;

      .btn {
        margin: 0;

        + .btn {
          margin-left: $size * 2;
        }

      }

    }

    > .btn {
      margin-right: 0;
      margin-left: auto;

      &__tight {
        margin-left: 0;
      }

    }

  }

  .checkout {

    label {
      display: inline-flex;
      align-items: center;
      margin-bottom: $size * 2;
      width: auto;

      @include themed() {
        color: t($color__primary);
      }

      input {
        margin: 0 $size 0 0;
        width: auto;
        height: auto;

        &:focus {
          box-shadow: none;
          border: none;
        }

      }

      + label {
        margin-left: $size * 2;
      }

    }

    form > div:first-child {
      display: table;
      align-items: center;
      height: $size * 5;
      line-height: $size * 5;
      padding: 0 ($size * 2);
      border-radius: $size / 2;
      width: 100%;

      @include themed() {
        box-shadow: inset 0 0 0 1px t($color__input);
      }

      .StripeElement {
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;

        div {
          width: 100%;
        }

      }

    }

    .error {
      display: block;
      color: red;
      margin-top: $size;
    }

    .field__buttons {
      margin-top: $size * 7;
      margin-bottom: -($size * 11)
    }

  }

  .footnote {
    display: flex;
    align-items: center;
    
    svg {
      display: block;
      margin-right: $size * 2;
    }

  }

}

form {

  .hide {
    display: none;
  }

  hr {
    margin: ($size * 4) 0;
  }

  h3 {
    font-size: 14px;
    font-family: 'basier_circleregular';
    font-weight: 300;
    margin: 0;

    @include themed() {
      color: t($color__text);
    }

    // margin: ($size * 2) 0;
  }

}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active  {
  @include themed() {
    background-color: #fff!important;
    -webkit-text-fill-color: t($color__primary) !important;
  }
}

.notifications {
  position: relative;
  margin: auto (-$size * 4) 0 (-$size * 4);

  .alert {
    padding: ($size * 4);
    border-radius: $size;
    transition: all 0.1s ease-in-out;

    @include themed() {
      background: t($color__bar__empty);
      background: transparent;
      border-radius: 0;
      border-top: 1px solid rgba(t($color__text), 0.05);
    }

    + .alert {
      margin-top: 0;
    }

    .alert__title {
      position: relative;
      display: flex;
      align-items: center;
      // height: $size * 8;
      margin-bottom: $size;

      @include themed() {
        color: t($color__primary);

        > svg {
          overflow: visible;
          width: $size * 2;
          height: $size * 2;
          transition: all 0.2s ease-in-out;

          g,
          path,
          ellipse {
            fill: t($color__positive);
          }

        }

        &.warning {

          > svg {

            g,
            path,
            ellipse {
              fill: t($color__negative);
            }

          }

        }

      }

      h3 {
        margin: 0 0 0 $size;
      }

      span {
        position: absolute;
        top: -$size * 2;
        right: -$size * 2;
        cursor: pointer;

        @include themed() {

          svg {
            overflow: visible;

            g,
            path,
            ellipse {
              fill: t($color__text);
            }

          }

        }

      }

    }

    .alert__text {
      transition: all 0.2s ease-in-out;
      // padding-bottom: $size * 3;

      p {
        font-family: 'basier_circleregular';
        margin: 0;
        font-size: 14px;

        @include themed() {
          color: t($color__text);
        }

      }

      a {
        display: none;
        // padding: $size * 2;
        margin-top: $size * 3;
        // text-align: center;
        // font-family: 'basier_circlemedium';
        border-radius: $size;
        // transition: all 0.1s ease-in-out;

        @include themed() {
          background: transparent;
          box-shadow: inset 0 0 0 1px t($color__text);
          color: t($color__text);
        }

      }

    }

    &__hidden {
      opacity: 0;
    }

  }

}

.component__stats {
  justify-items: stretch;
  align-items: stretch;
  overflow: visible;

  @media (min-width: 1024px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: $size * 2;
    grid-row-gap: $size * 2;
  }

}

.component__today {

  .header {
    display: flex;
    align-items: center;
    height: $size * 8;
  }

  .item {
    padding: $size 0;

    &__work {
      display: flex;
      align-items: center;
      position: relative;
      height: $size * 6;
      border-radius: $size / 2;
      padding: 0 ($size * 2);

      @include themed() {
        background: t($color__secondary);
      }

    }

    em {
      position: absolute;
      top: $size + 2;
      bottom: $size + 2;
      left: $size + 2;
      width: $size / 2;
      border-radius: 2px;

      @include themed() {
        background: t($color__primary);
      }

    }

    article {
      flex: 1;
    }

    span,
    small {
      padding-left: $size * 2;
    }

    span {

      b {
        margin-left: $size / 2;
        font-weight: 300;
      }

    }

    small {
      opacity: .7;
      font-weight: 300;
      font-family: "basier_circleregular";
      margin: 0;
      display: block;
      font-size: 10px;
    }

  }

}

.component__route {

  ul.errors {
    display: block;
    background: #FF4136;
    min-width: 600px;
    max-width: 600px;
    padding: $size * 2;
    color: #fff;
    border-radius: ($size / 2) ($size / 2) 0 0;
    margin: 0 0 -($size) 0;
    text-align: center;
    position: relative;
    z-index: 2;
  }

  .notice,
  .success {
    position: fixed;
    right: $size * 3;
    top: $size * 3;
    background: red;
    padding: 0 ($size * 2);
    height: $size * 5;
    line-height: $size * 5;
    text-align: center;
    color: #fff;
    border-radius: ($size / 2);
  }

  .success {

    @include themed() {
      background: t($color__positive);
    }

    // -moz-animation: hide 0s ease-in 2s forwards;
    // -webkit-animation: hide 0s ease-in 2s forwards;
    // -o-animation: hide 0s ease-in 2s forwards;
    // animation: hide 0s ease-in 2s forwards;
    // -webkit-animation-fill-mode: forwards;
    // animation-fill-mode: forwards;
  }

}

.example {
  padding-top: $size * 2;
}

.checkout {

  form > div:first-child {
    display: table;
    align-items: center;
    height: $size * 5;
    line-height: $size * 5;
    padding: 0 ($size * 2);
    border-radius: $size / 2;
    width: 100%;

    @include themed() {
      box-shadow: inset 0 0 0 1px t($color__input);
    }

    .StripeElement {
      display: flex;
      width: 100%;
      height: 100%;
      align-items: center;

      div {
        width: 100%;
      }

    }

  }

  .error {
    display: block;
    color: red;
    margin-top: $size;
  }

  .field__buttons {
    margin-top: $size * 3;
  }

}

.invoices {
  padding: 24px 32px;
  margin: 40px -32px -16px -32px;

  @include themed() {
    border-top: 1px solid t($color__border);
    background: rgba(t($color__border), 0.2);
  }

  @media (min-width: 640px) {
    padding: 24px 64px;
    margin: 40px -64px -32px -64px;
  }

  .invoice {
    display: flex;
    justify-content: space-between;
    margin: ($size * 2) 0;

    a {
      opacity: 0.5;
    }

  }

}

.trial {
  display: flex;
  align-items: center;
  padding: $size ($size * 1.5);
  border-radius: 0 0 8px 8px;
  padding: 24px 32px;
  margin: 40px -32px -16px -32px;

  @include themed() {
    border-top: 1px solid t($color__border);
    background: rgba(t($color__border), 0.2);
  }

  @media (min-width: 640px) {
    padding: 24px 40px;
    margin: 40px -64px -32px -64px;
  }

  svg {
    margin-right: $size;
  }

  @include themed() {
    border-top: 1px solid t($color__border);
  }

}

.feature {
  position: fixed;
  top: 0;
  left: 73px;
  right: 0;
  bottom: 0;
  z-index: 20;
  display: flex;
  align-items: center;
  justify-content: center;

  &__bg {
    position: fixed;
    top: 0;
    left: 72px;
    right: 0;
    bottom: 0;

    @include themed() {
      background: t($color__modal__bg);
      -webkit-backdrop-filter: saturate(0%);
      backdrop-filter: saturate(0%);
    }

    &[disabled] {
      pointer-events: none;
    }

  }

  &__block {
    z-index: 10;
    width: 600px;
    border-radius: ($size * 2);
    max-width: 600px;
    max-height: 80vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: auto;
    margin-right: auto;
    transform-origin: center top;
    transition: all 0.4s ease-in-out;
    padding: $size * 5;
    padding-bottom: 0;

    @include themed() {
      background: t($color__modal);
    }

    h2 {
      font-size: 24px;
      line-height: 32px;
      margin: 0;
      max-width: 75%;
    }

    p {
      font-size: 15px;
      line-height: 24px;
      opacity: 0.5;
    }

    .video {
      width: 100%;

      img {
        filter: grayscale(1);
        margin-top: $size * 2;
        display: block;
        width: 100%;
      }

    }

    .pill {
      margin-bottom: -($size * 2);
    }

    .content {

      &__loading {
        opacity: 0;
      }

      > :not(template) ~ :not(template) {
        margin: ($size * 3) 0 0 0;
      }

    }

  }

  .choice {
    width: 100%;
    padding-bottom: $size * 5;

    > :not(template) ~ :not(template) {
      margin-top: $size * 2;
    }

    button {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: ($size * 2) ($size * 3);
      border-radius: $size;
      cursor: pointer;
      font-size: 16px;
      font-family: 'basier_circlemedium';
      text-align: left;

      @include themed() {
        color: t($color__primary);
        border: 1px solid t($color__nav__active);

        &:hover {
          background: rgba(t($color__accent), 0.1);
          border: 1px solid t($color__accent);
        }

      }

      span {
        font-size: 14px;
        opacity: 0.5;
        font-family: 'basier_circleregular';
      }

    }

  }

}

nav.demo {
  padding-bottom: 88px;
}

main.demo {

  > section {
    padding-bottom: 88px;

    &.component__schedule {
      padding: 0;
      margin-bottom: 104px;
    }
  }

}

div.demo {
  position: fixed;
  right: $size * 2;
  left: $size * 2;
  bottom: $size * 2;
  z-index: 200000000000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ($size * 2);
  border-radius: $size;

  @include themed() {
    background: t($color__positive);
    color: t($color__secondary);

    path {
      stroke: t($color__secondary);
    }

  }

  > span {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-family: 'basier_circlemedium';
    font-weight: 300;
    margin-left: $size * 2;

    svg {
      margin-right: $size;
    }

  }

  .btn {
    display: flex;
    align-items: center;
    margin-right: 0;
    height: auto;
    font-family: 'basier_circlemedium';
    border-radius: $size;

    @include themed() {
      background: transparent;
      color: t($color__secondary);
      border: 2px solid t($color__secondary);
    }

    svg {
      margin-left: $size;

      @include themed() {
        path {
          stroke: t($color__secondary);
        }
      }

    }

  }

}

::-webkit-scrollbar {
  width: 0;
}

#crisp-chatbox {

  > div {

    > div,
    > a {
      left: 8px!important;
      bottom: 8px!important;
    }

    > a {
      transform: scale(0.5);
    }

  }

}