.message {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: ($size * 2) ($size * 4);
  border-radius: $size;
  font-family: 'basier_circlemedium';
  margin-bottom: $size * 4;

  @include themed() {
    color: t($color__secondary);
    background: t($color__positive);
  }

  svg {
    margin-right: $size * 2;
  }

  &__error {

    @include themed() {
      background: t($color__negative);

      path {
        stroke: #fff;
      }

    }

  }

  &__issue {

    @include themed() {
      background: t($color__issue);
      color: t($color__root);

      path {
        stroke: t($color__root);
      }

    }

  }

  &__tiny {
    width: fit-content;
    padding: ($size) ($size * 1.5);
    border-radius: $size / 2;

    svg {
      margin-right: $size;
      width: $size * 2;
      height: $size * 2;
    }

  }

  hr {
    margin: ($size * 3) 0;
    opacity: 0.1;
  }

  .list {
    display: flex;

    > :not(template) ~ :not(template) {
      margin-left: $size;
    }

  }

}
