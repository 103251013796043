.component__schedule {
  position: relative;

  @include themed() {
    background: t($color__secondary);
  }

  header {
    display: none;
  }

  @media (min-width: 1024px) {
    margin: $size * 2;
    margin-left: 0;
    border-radius: $size;

    @include themed() {
      box-shadow: 0 0 0 1px t($color__border);
    }

  }

  .schedule__calendar {
    padding: 0;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    > div:first-child,
    > div:last-child {
      display: none;

      @media (min-width: 1024px) {
        display: flex;
      }

    }

    @media (max-width: 1024px) {

      > div {
        min-height: auto!important;
      }

    }

    .top,
    .bottom {
      display: flex;
      align-items: center;
      height: $size * 8;
      width: 100%;
    }

    .top {
      display: none;

      @media (min-width: 1024px) {
        display: flex;
      }

    }

    .top {
      margin-bottom: -($size * 2);
    }

    .schedule__calendar__month {
      position: relative;
      display: none;
      padding: ($size * 2) 0;

      @media (min-width: 1024px) {
        display: block;
        padding: 0;
      }

      > div {
        > div {
          transform: none!important;
        }
      }

      &:first-child {
        display: block;

        @media (min-width: 1024px) {
          display: block;
        }

      }

    }

    .sticky {
      z-index: 11;
      transform: none!important;
      border-radius: $size $size 0 0;
      overflow: hidden;
      overflow: hidden;

      @include themed() {
        box-shadow: 0px 1px 0 0 t($color__border);
      }

      .month {
        margin-right: $size * 2;
        padding-right: $size * 2;
      }

    }

    .month {
      display: none;

      @media (min-width: 1024px) {
        display: flex;
      }

      @include themed() {
        background: rgba(t($color__secondary), 0.9);
        backdrop-filter: blur(20px);
      }

      &.border {
        position: sticky;
        top: 0;
        z-index: 3;

        @include themed() {
          // box-shadow: 0px 1px 0 0 t($color__nav);
          box-shadow: 0px 1px 0 0 t($color__border);
        }

        &__top {
          position: sticky;
          bottom: 0;
          margin-top: $size;

          span {
            display: block;
            width: 100%;
            text-align: center;
            opacity: 0.5;
          }

          @include themed() {
            //box-shadow: 0px -1px 0 0 t($color__nav);
            box-shadow: 0px -1px 0 0 t($color__border);
          }

        }

      }

      justify-content: space-between;
      align-items: center;
      height: $size * 8;
      padding: 0 ($size * 3);

      @media (min-width: 640px) {
        padding: 0 ($size * 5);
      }

      div {
        font-weight: 300;
        color: $color__text;
        font-size: 14px;
      }

    }

    .week {
      position: relative;
      display: flex;
      align-items: center;
      height: $size * 7;
      width: 100%;

      &.monthStart {
        margin-top: -($size * 2);
      }

      &:hover {

        button {

          @include themed() {
            color: t($color__accent);
          }

        }

      }

      &::after {
        content: "";
        height: 1px;
        width: 100%;
        position: absolute;
        top: 50%;
        opacity: 0.5;
        
        @include themed() {
          background: t($color__schedule);
        }

      }

      > div {
        position: relative;
        z-index: 2;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 ($size * 4);

        > span {
          display: inline-block;
          padding: 0 $size;
          
          @include themed() {
            color: t($color__text);
            background: t($color__secondary);
          }

        }

        button {
          display: flex;
          align-items: center;
          padding: $size;
          margin: 0;
          text-transform: uppercase;
          font-size: 10px;
          border-radius: 4px;

          @include themed() {
            color: t($color__text);
            background: t($color__secondary);
          }

        }

      }

      + .consecutive_client {

        .day__work div::before {
          display: none;
        }

      }

    }

    .day {
      display: flex;
      height: $size * 7;
      padding: 0 ($size * 3) 0 0;
      width: 100%;
      user-select: none;

      * {
        user-select: none;
      }

      @media (min-width: 1024px) {
        padding: 0 ($size * 5) 0 0;
      }

      .day__date {
        cursor: pointer;
      }

      &__date {
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-width: $size * 14;
        font-family: 'basier_circleregular';
        margin: 0;
        padding: 0;
        text-align: center;
        line-height: 1;

        .bg {
          font-size: 10px;
          margin-top: $size / 2;
        }

      }

      &__work {
        display: grid;
        grid-auto-columns: 1fr;
        grid-auto-flow: column;
        width: 100%;
        height: 100%;
        padding: ($size/2) 0;
        flex: 1 0 0;

        @include themed() {
          color: t($color__secondary);
        }

        div {
          position: relative;
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex: 1;
          border-radius: 4px;
          padding: $size ($size * 2);
          transition: text-indent 0.5s ease;

          @include themed() {
            color: t($color__schedule__text);
            box-shadow: inset 0 0 0 1px t($color__schedule);
          }

          article {
            display: flex;
            flex-direction: column;
            width: 100%;
            z-index: 2;
          }
          
          > .tooltip {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
          }

          > .tooltip .tooltip-trigger {
            padding: 16px 10px;
            z-index: 10;

            &:hover {
              @include themed() {
                background: rgba(t($color__primary), 0.05);
                border-radius: $size / 2;
              }
            }

          }

          em {
            position: absolute;
            top: $size;
            left: $size;
            bottom: $size;
            width: $size / 2;
            border-radius: 2px;
            z-index: 2;

            .tooltip,
            .tooltip-trigger {
              height: 100%;
              width: 100%;
            }

            .tooltip-trigger {
              padding: $size * 2;
            }

            em {
              position: absolute;
              top: 0;
              left: 0;
              max-height: 100%;
            }

            @include themed() {
              background: t($color__secondary);
            }

          }

          span.title {
            display: none;
            padding-left: $size * 2;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-right: $size * 5;

            b {
              display: inline;
              margin-left: $size / 2;
              opacity: 0;
              font-weight: 300;
            }

            &.hours {
              display: none;
              padding-left: 0;
              text-align: center;
              width: 100%;

              @include themed() {
                color: t($color__primary);
                opacity: 0.7;
              }

            }

          }

          span.meta {
            // display: none;
            opacity: .7;
            font-weight: 300;
            font-family: "basier_circleregular";
            margin: 0;
            font-size: 10px;
            padding-left: $size * 2;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-right: $size * 5;
            width: 100%;
          }

          span.acronym {
            position: absolute;
            right: 16px;
            z-index: 2;
          }

          button {
            opacity: 0;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            width: $size * 8;
            height: auto;
            cursor: pointer;
            font-size: 0;
            border: none;
            z-index: 2;
            outline: none;
            transition: opacity 0.1s ease-in-out;
            background-color: transparent;
          }

          &.blackout {
            opacity: 1;

            @include themed() {
              background-color: t($color__blackout);
              box-shadow: none;
            }

            small.meta {
              display: flex;
              font-size: 14px;
              margin-left: $size * 2;

              @include themed() {
                color: t($color__blackout__text);
              }

            }

            &::after {
              content: "";
              position: absolute;
              top: $size + 2;
              bottom: $size + 2;
              left: $size + 2;
              width: $size / 2;
              border-radius: $size / 2;

              @include themed() {
                background: t($color__secondary);
              }

            }

          }

          &.hover:not(.add__quick) {
            cursor: pointer;
            position: relative;
            opacity: 1;

            @include themed() {
              background: t($color__accent);
              box-shadow: none;
            }

            &::after {
              content: "";
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              border-radius: 4px;
            }

            &.add {

              span {
                display: block;

                &.hours {
                  display: none;
                }

              }

              &::after {
                content: "";
                position: absolute;
                top: $size + 2;
                bottom: $size + 2;
                left: $size + 2;
                width: $size / 2;
                border-radius: $size / 2;

                @include themed() {
                  background: t($color__secondary);
                }

              }

              &::before {
                content: "";
                position: absolute;
                right: 0;
                top: 0;
                width: $size * 8;
                bottom: 0;

                @include themed() {
                  background: url('../images/add__' + t($color__icon) + '.svg') center no-repeat;
                }

              }

              button {
                display: none;
              }

            }

            &.blackout {

              small.meta {
                display: none;
              }

            }

          }

        }

      }

      &.weekend:not(.booked):not(.shift):not(:hover):not([data-select=true]) {

        .day__work {

          div:not(.blackout) {

            @include themed() {
              opacity: 0.5;
            }

            &.hover {

              @include themed() {
                background: t($color__accent);
              }

            }

            span.hours {
              display: none;
            }

          }

        }

      }

      &.booked {

        &.holiday {

          .day__work {

            div {

              span.title {
    
                svg {
                  opacity: 1;
                }
    
              }

            }

          }

        }

        .day__work {

          div {
            flex: 1;
            margin-left: $size;
            opacity: 1;
            min-width: 0;

            @include themed() {
              background: t($color__schedule__booked);
              color: t($color__schedule__booked__text);
              box-shadow: none;
            }

            button {
              display: none;
            }

            &:hover {

              small.meta {

                .meta__rate {
                  position: relative;
                  display: block;
                }

                span + span {

                  &::before {
                    content: "–";
                    padding-right: $size / 2;
                  }

                }

              }

            }

            &:first-child {
              margin-left: 0;
            }

            &.add__quick {
              display: none;
              flex-grow: 0;
              flex-basis: $size * 8;

              small {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0 ($size * 2);
                font-size: 13px;
                line-height: 1;

                @include themed() {
                  color: t($color__primary);
                  opacity: 0.7;
                }

              }

              @include themed() {
                background: transparent;
                box-shadow: inset 0 0 0 1px t($color__schedule__booked);
              }

              &.hover {
                cursor: pointer;

                small {
                  opacity: 0;
                }

                @include themed() {
                  background: t($color__accent) url('../images/add__' + t($color__icon) + '.svg') center no-repeat;
                  box-shadow: inset 0 0 0 1px t($color__accent);
                }

              }

            }

            &.invoice {
              width: $size * 8;
              flex: 0 0 auto;
              width: auto;
              padding: 0;

              @include themed() {
                background: t($color__positive);
              }

              &:hover {

                @include themed() {
                  background: t($color__accent);
                }

              }

              span {
                padding: 0;
              }

              button {
                opacity: 1;
                border-radius: $size / 2;
                font-size: inherit;
                white-space: nowrap;
                width: auto;
                position: static;
                padding: $size ($size * 2);
                height: 100%;

                svg {
                  display: block;
                  margin: 0;
                }

                &:hover {

                  @include themed() {
                    color: t($color__secondary);
                  }

                }

              }

            }

            span.title {
              display: flex;
              width: 100%;
  
              span.truncate {
                overflow: hidden;
                text-overflow: ellipsis;
              }

              svg {
                margin: 0 $size;
                opacity: 0.4;
              }
  
            }

            &.delete {
              font-size: inherit!important;
              z-index: 10;

              @include themed() {
                box-shadow: inset 0 0 0 2px t($color__accent), 0 0 0 $size t($color__secondary);
              }

              em {
                display: block!important;
              }

              &::before {
                display: none;
              }

            }

          }

        }

        &:hover,
        &.hover {

          .day__work {

            div {

              &.add__quick {
                display: block;
              }

            }

          }

          + div {

            .day__work {

              div {
                font-size: inherit!important;

                &::before {
                  opacity: 0!important;
                }

                svg,
                em {
                  display: block!important;
                }

                span.acronym {
                  display: block!important;
                }

              }

            }

          }

        }

      }

      &[data-select=true],
      &.shift,
      &.drop {

        .day__work {

          div {

            &.add,
            &.add__quick {
              display: flex;
              opacity: 1;

              @include themed() {
                background: t($color__accent);
                color: t($color__secondary);
                box-shadow: none;
                font-size: 0;
              }

              &::after {
                display: none;
              }

              small {
                display: none;
              }

              button {

                @include themed() {
                  background-image: none;
                }

              }

            }

          }

        }

        + .consecutive_client {

          .day__work {

            div {

              &::before {
                display: none;
              }

            }

          }

        }

      }

      &.hoursFree {

        .day__work {

          div {

            &.add,
            &.add__quick {
              display: flex;
              opacity: 1;
            }

          }

        }

        + .day.consecutive_client:not(.selected):not(.shift) {

          .day__work {

            div {
              font-size: inherit;

              em {
                display: block;
              }

              .meta {
                display: flex;
              }

              &::before {
                display: none;
              }

            }

          }

        }

      }

      &[data-select=true],
      &.drop,
      &.shift {

        .day__work {

          div {

            &.add,
            &.add__quick {
              display: flex;
              opacity: 1;

              @include themed() {
                background: t($color__accent)!important;
                color: t($color__secondary);
              }

            }

            &::before {
              display: none;
            }

          }

        }

      }

      &.invoice {

        .day__work {

          div {

            small.meta {
              display: flex;
            }

            span {

              b {
                opacity: 1;
              }

            }

          }

        }

        &.invoiced {

          .invoice {
            display: none;
          }

          &:hover,
          &:focus {

            .invoice {
              display: flex;
            }

          }

        }

      }

      &.selected {
        position: relative;

        &::before {
          content: "";
          position: absolute;
          top: $size / 2;
          left: $size * 4;
          width: $size * 6;
          height: $size * 6;
          border-radius: 100%;

          @include themed() {
            background: t($color__primary);
          }

        }

        .day__date {
          position: relative;
          z-index: 1;
          color: $color__secondary--light;

          @include themed() {
            color: t($color__secondary);
          }

        }

      }

      &.full {

        .day__work {
          opacity: 1;
        }

      }

      &.invoiced,
      &.paid {

        .day__work {

          div[draggable] {
            //pointer-events: none;
          }

        }

      }

      &.consecutive_client:not(.selected):not(.shift) {

        .day__work {

          div {
            font-size: 0;

            svg,
            em,
            .meta {
              display: none;
            }

            &::before {
              content: "";
              background-color: inherit;
              height: 24px;
              position: absolute;
              top: -20px;
              width: 100%;
              left: 0;
              right: 0;
              z-index: 0;
            }

            span.acronym {
              display: none;
            }

          }

        }

        &:hover {

          .day__work {

            div {
              font-size: inherit;

              svg,
              em,
              .meta {
                display: flex;
              }

              &::before {
                opacity: 0;
              }

              span.acronym {
                display: block;
              }

            }

          }

        }

      }

      &.shift {

        .day__work {

          div {

            &.add,
            &.add:hover {

              em,
              &::before,
              &::after {
                display: none;
              }

            }

            em {
              display: none!important;
            }

            button {
              background-image: none!important;
            }

            article {
              display: none!important;
            }

            small.meta {
              display: none!important;
            }

            &.add__quick {

              @include themed() {
                background: t($color__accent);
              }

            }

          }

        }

      }

      &.after {

        .day__work {

          div {

            &::before {
              display: none;
            }

          }

        }

      }

    }

    &.dragging {

      .day,
      .day.weekend {
        position: relative;

        &::after {
          content: "";
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          top: 0;
          z-index: 2;
        }

        &:hover {

          &::after {
            display: none;
          }

        }

        .day__work {

          &:hover {

            div:not(.dragging) {
              overflow: hidden;
              transform: translate(0, 0);

              &::before,
              &::after {
                display: none;
              }

            }

          }

        }

      }

    }

  }

}

.twitter-picker > div > div {
    position: relative;
    background: red;
}

.drop * {
 pointer-events: none!important;
}

.quick__note {
  position: fixed;
  z-index: 2;
  bottom: $size * 2;
  right: $size * 2;
  display: flex;
  align-items: center;
  padding: ($size * 2) ($size * 4);
  border-radius: $size * 4;
  opacity: 0;

  @include themed() {
    background: t($color__accent);
    color: t($color__secondary);
    box-shadow: 0 0 20px rgba(t($color__nav), 0.4);
  }

  &[data-shift=false] {
    display: none;
  }

  svg {
    margin-right: $size;
  }

  &__inline {
    opacity: 0;
    display: flex;
    align-items: center;
    white-space: nowrap;

    .actions:not(:empty) {
      display: flex;
      align-items: center;

      button {
        display: flex;
        align-items: center;
        width: auto;
        height: auto;
        font-size: inherit;
        background: none;
        padding: 0;

        @include themed() {
          color: t($color__primary);
        }

        svg {
          margin-right: $size;

          path {

            @include themed() {
              fill: t($color__accent);
            }

          }

        }

        + button {
          margin-left: $size * 3;
        }

      }

      + .helper {
        display: none;
      }

    }

    .helper {

      svg {
        margin-right: $size;

        path {
          @include themed() {
            fill: t($color__accent);
          }
        }

      }

    }

  }

}

.quick__note__inline {

  .helper {
    display: flex;
    align-items: center;
  }

}

.schedule__calendar__month:first-child {

  .quick__note__inline {
    opacity: 1;
  }

}

.sticky {

  .quick__note__inline {
    opacity: 1;
    padding-right: $size;
  }

}

.selectable-selectbox,
.selector > div:first-child > span:not(.day__date) {
  transform: translateZ(0), translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000;
  will-change: transform;

  @include themed() {
    background: rgba(t($color__accent), 0.4)!important;
    border: 1px solid t($color__secondary)!important;
    border-radius: 4px;
  }

}

.selector div:hover {

  + div {

    .consecutive_client {

      .day__work {

        div {

          &::before {
            display: none;
          }

        }

      }

    }

  }

}

.invoice_summary,
.schedule_summary {
  position: absolute;
  z-index: 2;
  right: $size * 5;
  border-radius: $size;
  padding: $size / 2;
  box-shadow: 0 0 0 1px rgba(0,0,0,.1), 0 10px 20px rgba(0,0,0,.1);
  min-width: 300px;
  font-size: 13px;
  max-height: 300px;
  overflow: hidden;

  @include themed() {
    background: t($color__summary);
  }

  .loader {
    margin: ($size * 2) auto;
  }

  &__item,
  &__footer {
    display: flex;
    justify-content: space-between;
    padding: ($size * 1.5) ($size * 2);

    .data {
      opacity: 0.5;
      margin-left: $size * 3;
    }

    .empty {
      width: 100%;
      opacity: 0.5;
    }

  }

  &__item:not(:first-child) {

    @include themed() {
      border-top: 1px solid t($color__summary__divider);
    }

  }

  &__footer {
    font-family: 'basier_circlemedium';
    border-radius: $size / 2;
    margin-top: $size / 2;

    @include themed() {
      background: t($color__summary__footer);
    }

    .data {
      opacity: 1;
    }

  }

}

.invoice_summary {
  right: auto;

  &.small {
    min-width: auto;
  }

}