.component__tasks {
  overflow: visible;
  width: 100%;

  h2 {
    padding-bottom: $size * 4;
  }

  ul {
    padding: 0;
    list-style: none;

    li {
      position: relative;
      display: flex;
      align-items: center;
      padding: 0;
      margin: ($size * 2) 0;

      &:first-child {
        margin-top: $size;
      }

      &.strike {

        span {

          @include themed() {
            background: t($color__primary);
          }

        }

        a {
          text-decoration: line-through;
          pointer-events: none;
        }

      }

      span {
        min-width: $size * 2.5;
        min-height: $size * 2.5;
        text-align: center;
        color: transparent;
        border-radius: 100%;

        @include themed() {
          box-shadow: inset 0 0 0 1px t($color__primary);
        }

        &:not(.new) {

          &:hover {
            cursor: pointer;

            @include themed() {
              background: t($color__primary);
            }

            ~ input {
              text-decoration: line-through;
            }

          }

        }

      }

      a {
        margin-left: $size * 1.5;
      }

      form {
        flex: 1;
      }

      input.form-control {
        width: 100%;
        border: none;
        background: none;
        padding: 0;
        margin: 0;
        outline: none;
        padding-left: $size * 1.5;
        transition: margin 0.1s ease-in-out;
        border-radius: 0;
        align-self: flex-start;

        @include themed() {
          color: t($color__primary);
        }

      }

    }

  }

}
