nav {
  display: flex;
  justify-content: space-between;
  padding: 0;
  text-align: center;

  @media (min-width: 640px) {
    padding: 0 ($size * 2);
  }

  @media (min-width: 1024px) {
    flex-direction: column;
    padding: ($size * 2) $size;
  }

  @include themed() {
    background: t($color__nav);
    box-shadow: 1px 0 0 0 rgba(t($color__text), 0.1);
    position: relative;
    z-index: 10;
  }

  .menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include scroll();

    @include themed() {
      box-shadow: inset 0 1px 0 rgba(t($color__grey), 0.1);
    }

    @media (min-width: 1024px) {
      flex-direction: column;
      justify-content: flex-start;
      overflow: visible;
      padding: $size;
      flex-grow: 1;

      @include themed() {
        box-shadow: none;
      }

    }

    a,
    button {

      &.bottom {
        margin-top: auto;

        + .bottom {
          margin-top: 0;
        }

      }

    }

    button.bottom {
      margin-bottom: $size * 5;
    }

  }

  a,
  button {
    position: relative;
    display: block;
    width: 100%;
    padding: ($size * 3);

    @media (min-width: 1024px) {
      padding: ($size * 1.5) $size;
      margin: ($size / 2) 0;
    }

    .tooltip {
      z-index: 1;
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: -($size / 2);
      bottom: 0;
      left: -($size / 2);
      z-index: 0;

      @media (min-width: 1024px) {
        border-radius: $size;
      }

    }

    &:hover,
    &:focus {
      box-shadow: none!important;

      &::after {
        @media (min-width: 1024px) {
          border-radius: $size;
  
          @include themed() {
            background: rgba(t($color__nav__active), 0.5);
          }
  
        }
      }

    }

    &.active {

      &::after {

        @include themed() {
          background: t($color__nav__active);
        }

      }

    }

    &.trial_remaining {
      width: 26px;
      height: 26px;
      border-radius: 50%;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      box-shadow: none;
      margin-bottom: $size * 1.5;

      @include themed() {
        background-image: conic-gradient(t($color__accent) var(--value), t($color__bar__empty) var(--value));
      }

      span {
        display: block;
        width: 24px;
        height: 24px;
        line-height: 24px;
        position: relative;
        z-index: 2;
        font-family: "basier_circlemedium";
        font-size: 11px;
      }

      &::after {
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: calc(100% - 4px);
        height: calc(100% - 4px);
        border-radius: inherit;

        @include themed() {
          background: t($color__nav);
        }

      }

      $max: 31;
      $step: 1;
      @for $i from 0 through ceil($max/$step) {
        $value: ($i - 1) * $step;
        &.x-#{$value} { --value: #{$i/30*100}%; }
      }

    }

    svg,
    img {
      display: none;
      margin: auto;

      @media (min-width: 1024px) {
        display: block;
      }

    }

    span {

      @include themed() {
        color: t($color__primary);
      }

      @media (min-width: 1024px) {
        display: none;
      }

    }

  }

  button {
    cursor: pointer;

    img {
      display: none;

      @media (min-width: 1024px) {
        display: block;
      }

    }

    span {
      color: #fff;

      @media (min-width: 1024px) {
        display: none;
      }

    }

  }

  figure {
    margin: 0;
    padding: ($size * 2);

    @media (min-width: 1024px) {
      padding: 0;
    }

    img {
      width: $size * 4;
      height: $size * 4;
      display: block;
      margin: 0 auto;

      @media (min-width: 1024px) {
        margin: ($size) auto;
      }

    }

  }

}
