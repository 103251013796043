
table {
  border-collapse: collapse;
  width: 100%;

  thead {
    position: sticky;
    top: 0;
    z-index: 1;

    @include themed() {
      background: t($color__secondary);
    }

    th {
      font-family: 'basier_circleregular';
      font-weight: 300;
      opacity: 0.5;

      > div {
        padding: $size * 2;
      }

    }

  }

  tbody {

    tr {

      &:hover {
        @include themed() {
          background: t($color__row__hover);
        }
      }

      &.voided {

        span:not(.pill) {
          text-decoration: line-through;
          opacity: 0.5;
        }

      }

    }

    th, td {
      @include themed() {
        border-top: 1px solid t($color__grey);
      }
    }

  }

  th, td {
    text-align: left;
    white-space: nowrap;
    padding: 0;
    width: 10%;

    &:first-child {
      padding-left: $size * 3;
    }

    &:last-child {
      padding-left: $size * 3;
      padding-right: $size * 3;
    }

    &.fill {
      width: 100%;
    }

    &.right {
      text-align: right;

      .actions {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        > :not(template) ~ :not(template) {
          margin-left: $size;
        }

        a {
          margin: 0;
        }

      }

    }

    &.actions {
      position: relative;
    }

    > div {
      padding: ($size * 1.5) ($size * 2);

      &.note {
        text-align: center;
        padding: $size * 12;
      }

    }

  }

}