@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}


@-webkit-keyframes pulse {
  0%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }
}
@keyframes pulse {
  0%, 100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
}

@-webkit-keyframes hide {
    to {
        width:0;
        height:0;
        visibility:hidden;
    }
}
@keyframes hide {
    to {
        width:0;
        height:0;
        overflow:hidden;
    }
}



.conf0{fill:#FC6394;}
.conf1{fill:#EF3C8A;}
.conf2{fill:#5ADAEA;}
.conf3{fill:#974CBE;}
.conf4{fill:#3CBECD;}
.conf5{fill:#813BBE;}
.conf6{fill:#F9B732;}
.conf7{display:none;fill:none;stroke:#000000;stroke-miterlimit:10;}
.conf8{fill:none;stroke:#F9B732;stroke-width:9;stroke-linecap:round;stroke-miterlimit:10;}


.confetti-cone{
  transform-origin: 200px 50px;
  animation:confetti-cone1 1.2s ease infinite;
}
@keyframes confetti-cone1{
0%{
   transform:translate(40px, 95px)  rotate(45deg) scale(1, 1);
}
15%{
   transform:translate(10px, 145px)  rotate(45deg) scale(1.1, 0.85);

}
100%{
  transform:translate(40px, 105px)  rotate(45deg) scale(1, 1);
}
  }

#yellow-strip {
  fill:none;
  stroke:#F9B732;
  stroke-width:9;
  stroke-linecap:round;
  stroke-miterlimit:10;
  animation: confdash 1.2s ease infinite;
}


@keyframes confdash {
0%{
  stroke-dasharray:1000;
  stroke-dashoffset:500;
  transform:translate(-30px, 30px);
  opacity:0;
}
2%{
  stroke-dasharray:1000;
  stroke-dashoffset:500;
  transform:translate(-30px, 30px);
  opacity:0;
}
35%{
stroke-dasharray:1000;
stroke-dashoffset:900;
transform:translate(-2px, 0px);
opacity:1;
}

85%{
  stroke-dasharray:1000;
  stroke-dashoffset:1000;
  transform:translate(1px, -5px);
  opacity:1;
}
  90%{
  stroke-dashoffset:1000;
   stroke-dashoffset:1000;
  transform:translate(2px, -8px);
  opacity:0;
}
  100%{
  stroke-dashoffset:1000;
   stroke-dashoffset:500;
  transform:translate(2px, -8px);
  opacity:0;
}
}



#a2{
  transform-origin: 310.9px 241px;
  animation: confa 1.2s ease-out infinite;
}

#a1
{transform-origin: 276px 246px;
  animation: confa 1.2s ease-out infinite;
}

@keyframes confa {
0%{
  opacity:0;
  transform: translate(-30px, 20px) rotate(0);
}
15%{
  opacity:1;
  transform: translate(25px, -10px) rotate(60deg);
}
80%{
  opacity:1;
  transform: translate(33px, -18px) rotate(180deg);
}
100%{
  opacity:0;
  transform: translate(37px, -23px) scale(0.5)rotate(230deg);
}
}


#b1{
  transform-origin: 195.2px 232.6px;
  animation: confb 1.2s ease-out infinite;
}

#b2{
  transform-origin: 230.8px 219.8px;
  animation: confb 1.2s ease-out infinite;
}
#b3 {transform-origin: 222.8px 190.6px;
  animation: confb 1.2s ease-out infinite;
}

#b4 {transform-origin: 262px 188.5px;
  animation: confb 1.2s ease-out infinite;
}

#b5 {transform-origin: 282.3px 170.6px;
  animation: confb 1.2s ease-out infinite;
}


@keyframes confb {
0%{
  opacity:0;
  transform: translate(-30px, 20px) rotate(0);
}
12%{
  opacity:1;
  transform: translate(25px, -10px) rotate(60deg);
}
76%{
  opacity:1;
  transform: translate(33px, -18px) rotate(180deg);
}
100%{
  opacity:0;
  transform: translate(37px, -23px) scale(0.5) rotate(240deg);
}
}

#c1 {transform-origin: 174.8px 183.4px;
  animation: confc 1.2s ease-out infinite;
}
#c2{
  transform-origin: 178.9px 156.2px;
  animation: confc 1.2s ease-out infinite;
}

#c3{
  transform-origin: 206.7px 140px;
  animation: confc 1.2s ease-out infinite;
}

#c4{
  transform-origin: 213.5px 120.2px;
  animation: confc 1.2s ease-out infinite;
}

@keyframes confc {
0%{
  opacity:0.7;
  transform: translate(-30px, 20px) rotate(0);
}
18%{
  opacity:1;
  transform: translate(5px, -10px) rotate(60deg);
}
76%{
  opacity:1;
  transform: translate(13px, -18px) rotate(180deg);
}
100%{
  opacity:0;
  transform: translate(17px, -23px) scale(0.5) rotate(230deg);
}
}

#d1{
  transform-origin: 127px 176px;
  animation: confd 1.2s ease-out infinite;
}
#d2 {transform-origin: 133px 118px;
  animation: confd 1.2s ease-out infinite;
}
#d3{ transform-origin: 152px 100px;
  animation: confd 1.2s ease-out infinite;
}

@keyframes confd {
0%{
  opacity:0.7;
  transform: translate(-20px, 20px) rotate(0);
}
18%{
  opacity:1;
  transform: translate(-5px, -10px) rotate(60deg);
}
76%{
  opacity:1;
  transform: translate(-8px, -18px) rotate(180deg);
}
100%{
  opacity:0;
  transform: translate(-10px, -23px) scale(0.5) rotate(230deg);
}
}
