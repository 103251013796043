.component__invoice {
  position: relative;
  padding: 0;
  overflow: visible;

  @include themed() {
    background: t($color__secondary);
  }

  @media (min-width: 1024px) {
    border-radius: $size;

    @include themed() {
      box-shadow: 0 0 0 1px t($color__border);
    }

  }

  + .component__invoice {
    margin-top: $size * 4;
  }
  
  .summary {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-shrink: 0;

    @include themed() {
      background: rgba(t($color__border), 0.2);
    }

    .data {
      display: flex;
      flex-wrap: wrap;
      flex: 1;
      padding: ($size * 5) 0;

      h2 {
        width: 100%;
      }

    }


  }

  .options {

    &.inset {
      @include themed() {
        background: none;
      }
    }

  }

}